<template>
    <div>
        <div style="display: flex;margin-bottom:15px;justify-content: flex-end">
            <v-menu offset-y open-on-hover bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title
                                style="cursor: pointer"
                                @click="changeSettings(item)"
                        >{{
                            !item.styles.listingSettings
                            ? "Style settings"
                            : "Content settings"
                            }}</v-list-item-title
                        >
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <div v-if="item.styles.listingSettings" style="margin-bottom:15px;">
            <div style="margin-bottom:15px;">
                <div>
                    <label class="label" style="font-weight:bold;">Global Settings:</label>
                </div>
                <div class="field">
                    <label class="label"
                    >Choose your listing style</label
                    >
                    <div class="socials-style">
                        <div
                                class="socials-style__item socials-style__item_default socials-style__listing__vertical"
                                style="height: 110px; width: 100px"
                                :class="{
              active: item.styles.displayType === 'default',
            }"
                                @click="changeListingStyle(item, 'default')"
                        >
                            <img src="@/assets/icons/default.png" alt="" />
                        </div>
                        <div
                                class="socials-style__item socials-style__item_default socials-style__listing__inside"
                                style="height: 110px; width: 100px"
                                :class="{
              active: item.styles.displayType === 'inside',
            }"
                                @click="changeListingStyle(item, 'inside')"
                        >
                            <img src="@/assets/icons/inside.png" alt="" />
                        </div>
                    </div>
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                        <div><label class="label" style="width:250px;margin-bottom:0;">Listing Vector</label></div>
                        <div>
                            <v-radio-group v-model="item.styles.listing_vector" row hide-details>
                                <v-radio label="Vertical" value="vertical" style="margin-right:15px;"></v-radio>
                                <v-radio label="Horizontal" value="horizontal"></v-radio>
                            </v-radio-group>
                        </div>
                    </div>
                    <div v-if="item.styles.displayType === 'inside'"
                         style="display: flex; align-items: center;margin-bottom:15px;"
                    >
                        <label class="label" style="margin: 0; width: 250px"
                        >Position</label
                        >
                        <v-select
                                style="max-width: 200px;"
                                flat
                                dense
                                outlined
                                hide-details
                                placeholder="Select position"
                                :items="['top', 'center', 'bottom']"
                                class="canvas__select"
                                v-model="content.styles.content_position"
                        >
                        </v-select>
                    </div>
                    <ListingBackground :item="item"/>
                    <ListingLiningBackground :item="item"/>
                </div>
            </div>
            <div style="margin-bottom:15px;">
            <div>
                <label class="label" style="font-weight:bold;">Title Settings:</label>
            </div>
            <div class="field">
                <div
                        style="
            display: flex;
            align-items: center;
            margin-bottom: 15px;
          "
                >
                    <label class="label" style="margin-bottom: 0; width: 250px"
                    >Color
                    </label>

                    <v-menu
                            top
                            nudge-left="16"
                            nudge-bottom="98"
                            :close-on-content-click="false"
                            v-model="show_title_color"
                    >
                        <template v-slot:activator="{ on }">
                            <div
                                    class="colorpicker"
                                    :style="`background-color:${content.styles.title.color}`"
                                    v-on="on"
                            />
                        </template>
                        <v-card>
                            <v-card-text class="pa-0">
                                <v-color-picker
                                        v-model="content.styles.title.color"
                                        flat
                                />
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </div>
                <div style="display:flex;align-items: center;margin-bottom:15px;">
                    <label class="label" style="min-width:242px;margin-bottom:0;"
                    >Font Size
                        <b>{{ content.styles.title.fontSize }}</b>
                        px</label
                    >
                    <div class="slider" style="width:100%;">
                        <v-slider
                                hide-details
                                :min="4"
                                :max="30"
                                v-model="content.styles.title.fontSize"
                        ></v-slider>
                    </div>
                </div>
                <div style="display:flex;align-items: center;margin-bottom:15px;">
                    <label class="label" style="min-width:242px;margin-bottom:0;"
                    >Font Weight
                        <b>{{ parseInt(content.styles.title.fontWeight, 10) / 10 }}%</b>
                        %</label
                    >
                    <div class="slider" style="width:100%;">
                        <v-slider
                                hide-details
                                :min="100"
                                :step="100"
                                :max="1000"
                                v-model="content.styles.title.fontWeight"
                        ></v-slider>
                    </div>
                </div>
                <div style="display: flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="width:250px;margin-bottom:0;">
                        Font Family
                    </label>

                    <v-select
                            flat
                            dense
                            outlined
                            hide-details
                            placeholder="Select Font Family"
                            :items="fontFamilyTypes"
                            item-text="label"
                            item-value="value"
                            class="canvas__select"
                            v-model="content.styles.title.fontFamily"
                            style="max-width:200px;"
                    >
                        <template v-slot:selection="{ item }">
                            <div :style="`font-family:'${item.value}',sans-serif`">
                                {{ item.label }}
                            </div>
                        </template>
                        <template v-slot:item="{ item }">
                            <div :style="`font-family:'${item.value}',sans-serif`">
                                {{ item.label }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div  style="display: flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:250px;margin-bottom:0;">
                    Font Style
                </label>
                <v-radio-group row v-model="content.styles.title.fontStyle" hide-details>
                    <v-radio
                            v-for="type in fontStyleTypes"
                            :key="type.id"
                            :label="type.label"
                            :value="type.value"
                            style="margin-right: 15px"
                    >
                    </v-radio>
                </v-radio-group>
            </div>
            </div>
        </div>
            <div style="margin-bottom:15px;">
                <div>
                    <label class="label" style="font-weight:bold;">Name Settings:</label>
                </div>
                <div class="field">
                    <div style="display:flex;align-items: center;margin-bottom:15px;">
                        <label class="label" style="min-width:242px;margin-bottom:0;"
                        >Font Size
                            <b>{{ content.styles.nameFontSize }}</b>
                            px</label
                        >
                        <div class="slider" style="width:100%;">
                            <v-slider
                                    hide-details
                                    :min="4"
                                    :max="30"
                                    v-model="content.styles.nameFontSize"
                            ></v-slider>
                        </div>
                    </div>
                    <div style="display: flex;align-items:center;margin-bottom:15px;">
                        <label class="label" style="width:250px;margin-bottom:0;">
                            Font Family
                        </label>

                        <v-select
                                flat
                                dense
                                outlined
                                hide-details
                                placeholder="Select Font Family"
                                :items="fontFamilyTypes"
                                item-text="label"
                                item-value="value"
                                class="canvas__select"
                                v-model="content.styles.fontFamily"
                                style="max-width:200px;"
                        >
                            <template v-slot:selection="{ item }">
                                <div :style="`font-family:'${item.value}',sans-serif`">
                                    {{ item.label }}
                                </div>
                            </template>
                            <template v-slot:item="{ item }">
                                <div :style="`font-family:'${item.value}',sans-serif`">
                                    {{ item.label }}
                                </div>
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
            <div style="margin-bottom:15px;">
                <div>
                    <label class="label" style="font-weight:bold;">Description Settings:</label>
                </div>
                <div class="field">
                    <div style="display:flex;align-items: center;margin-bottom:15px;">
                        <label class="label" style="min-width:242px;margin-bottom:0;"
                        >Font Size
                            <b>{{ content.styles.descriptionFontSize }}</b>
                            px</label
                        >
                        <div class="slider" style="width:100%;">
                            <v-slider
                                    hide-details
                                    :min="4"
                                    :max="30"
                                    v-model="content.styles.descriptionFontSize"
                            ></v-slider>
                        </div>
                    </div>
                    <div style="display: flex;align-items:center;margin-bottom:15px;">
                        <label class="label" style="width:250px;margin-bottom:0;">
                            Font Family
                        </label>
                        <v-select
                                flat
                                dense
                                outlined
                                hide-details
                                placeholder="Select Font Family"
                                :items="fontFamilyTypes"
                                item-text="label"
                                item-value="value"
                                class="canvas__select"
                                v-model="content.styles.fontFamilyDescription"
                                style="max-width:200px;"
                        >
                            <template v-slot:selection="{ item }">
                                <div :style="`font-family:'${item.value}',sans-serif`">
                                    {{ item.label }}
                                </div>
                            </template>
                            <template v-slot:item="{ item }">
                                <div :style="`font-family:'${item.value}',sans-serif`">
                                    {{ item.label }}
                                </div>
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
            <div v-if="item.styles.displayType === 'inside'" style="margin-bottom:15px;">
                <div>
                    <label class="label" style="font-weight:bold;">Lining Text Settings:</label>
                </div>
                <div class="field" >
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                        <label class="label" style="min-width:242px;margin-bottom:0;"
                        >Height
                            <b>{{ content.styles.lining_height }}</b>
                            px</label
                        >
                        <div class="slider" style="width:100%">
                            <v-slider
                                    hide-details
                                    :min="90"
                                    :max="300"
                                    v-model="content.styles.lining_height"
                            ></v-slider>
                        </div>
                    </div>
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                        <label class="label" style="min-width:242px;margin-bottom:0;"
                        >Width
                            <b>{{ content.styles.lining_width }}</b>
                            %</label
                        >
                        <div class="slider" style="width:100%">
                            <v-slider
                                    hide-details
                                    :min="10"
                                    :max="100"
                                    v-model="content.styles.lining_width"
                            ></v-slider>
                        </div>
                    </div>
                    <div style="display:flex;align-items:center;margin-bottom:15px">
                        <div>
                            <label class="label" style="width:250px;margin-bottom:0;">
                                Position
                            </label>
                        </div>
                        <div class="control">
                            <v-radio-group row v-model="position" hide-details>
                                <v-radio
                                        v-for="type in positionTypes.slice(0,3)"
                                        :key="type.id"
                                        :value="type.valueHorizontal+' '+type.valueVertical"
                                        @click="setPosition"
                                >
                                </v-radio>
                            </v-radio-group>
                            <v-radio-group row v-model="position" hide-details>
                                <v-radio
                                        v-for="type in positionTypes.slice(3,6)"
                                        :key="type.id"
                                        :value="type.valueHorizontal+' '+type.valueVertical"
                                        @click="setPosition"
                                >
                                </v-radio>
                            </v-radio-group>
                            <v-radio-group row v-model="position" hide-details>
                                <v-radio
                                        v-for="type in positionTypes.slice(6)"
                                        :key="type.id"
                                        :value="type.valueHorizontal+' '+type.valueVertical"
                                        @click="setPosition"
                                >
                                </v-radio>
                            </v-radio-group>
                        </div>
                    </div>
                    <div
                            style="
            display: flex;
            align-items: center;
            margin-bottom: 15px;
          "
                    >
                        <label class="label" style="margin-bottom: 0; width: 250px"
                        >Background Color
                        </label>

                        <v-menu
                                top
                                nudge-left="16"
                                nudge-bottom="98"
                                :close-on-content-click="false"
                                v-model="show_lining_color"
                        >
                            <template v-slot:activator="{ on }">
                                <div
                                        class="colorpicker"
                                        :style="`background-color:${content.styles.lining_color}`"
                                        v-on="on"
                                />
                            </template>
                            <v-card>
                                <v-card-text class="pa-0">
                                    <v-color-picker
                                            v-model="content.styles.lining_color"
                                            @input="show_lining_color = false"
                                            flat
                                    />
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </div>
                </div>
            </div>
            <div style="margin-bottom:15px;">
                <div>
                    <label class="label" style="font-weight:bold;">Block Settings:</label>
                </div>
                <div class="field">
                    <div style="display:flex;align-items: center;margin-bottom:15px;" v-if="item.styles.listing_vector === 'vertical'">
                        <label class="label" style="min-width:242px;margin-bottom:0;"
                        >Height
                            <b>{{ content.styles.height }}</b>
                            px</label
                        >
                        <div class="slider" style="width:100%;">
                            <v-slider
                                    hide-details
                                    :min="50"
                                    :max="500"
                                    v-model="content.styles.height"
                            ></v-slider>
                        </div>
                    </div>
                    <div  v-if="item.styles.listing_vector === 'horizontal'" style="display:flex;align-items: center;margin-bottom:15px;">
                        <label class="label" style="min-width:242px;margin-bottom:0;"
                        >Width
                            <b>{{ content.styles.width }}</b>
                            px</label
                        >
                        <div class="slider" style="width:100%;">
                            <v-slider
                                    hide-details
                                    :min="50"
                                    :max="400"
                                    v-model="content.styles.width"
                            ></v-slider>
                        </div>
                    </div>
                    <div style="display:flex;align-items: center;margin-bottom:15px;">
                        <label class="label" style="min-width:242px;margin-bottom:0;"
                        >Border Radius
                            <b>{{ item.styles.borderListingRadius }}</b>
                            px</label
                        >
                        <div class="slider" style="width:100%;">
                            <v-slider
                                    hide-details
                                    :min="0"
                                    :max="50"
                                    v-model="item.styles.borderListingRadius"
                            ></v-slider>
                        </div>
                    </div>
                    <div style="display:flex;align-items: center;margin-bottom:15px;">
                        <label class="label" style="min-width:242px;margin-bottom:0;"
                        >Border Size
                            <b>{{ content.styles.border_size }}</b>
                            px</label
                        >
                        <div class="slider" style="width:100%;">
                            <v-slider
                                    hide-details
                                    :min="0"
                                    :max="50"
                                    v-model="content.styles.border_size"
                            ></v-slider>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;margin-bottom: 15px;">
                        <div>
                            <label
                                    class="label"
                                    style="margin-bottom: 0;width:250px;"
                            >
                                Border color
                            </label>
                        </div>
                        <div class="control">
                            <v-menu
                                    top
                                    nudge-left="16"
                                    nudge-bottom="98"
                                    :close-on-content-click="false"
                                    v-model="show_listing_border_color"
                            >
                                <template v-slot:activator="{ on }">
                                    <div
                                            class="custom__menu--color"
                                            :style="`background-color:${content.styles.border_color}`"
                                            v-on="on"
                                    />
                                </template>
                                <v-card>
                                    <v-card-text class="pa-0">
                                        <v-color-picker v-model="content.styles.border_color" flat />
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </div>
                    </div>
                    <div style="display:flex;align-items: center;margin-bottom:15px;">
                        <label class="label" style="min-width:242px;margin-bottom:0;"
                        >Shadow Size
                            <b>{{ content.styles.shadow_size }}</b>
                            px</label
                        >
                        <div class="slider" style="width:100%;">
                            <v-slider
                                    hide-details
                                    :min="0"
                                    :max="50"
                                    v-model="content.styles.shadow_size"
                            ></v-slider>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;margin-bottom: 15px;">
                        <label
                                class="label"
                                style="margin-bottom: 0;width:250px;"
                        >
                            Shadow color
                        </label>
                        <div class="control">
                            <v-menu
                                    top
                                    nudge-left="16"
                                    nudge-bottom="98"
                                    :close-on-content-click="false"
                                    v-model="show_listing_shadow_color"
                            >
                                <template v-slot:activator="{ on }">
                                    <div
                                            class="custom__menu--color"
                                            :style="`background-color:${content.styles.shadow_color}`"
                                            v-on="on"
                                    />
                                </template>
                                <v-card>
                                    <v-card-text class="pa-0">
                                        <v-color-picker v-model="content.styles.shadow_color" flat />
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-bottom:15px;">
                <div>
                    <label class="label" style="font-weight:bold;">Image Settings:</label>
                </div>
                <div class="field">
                    <div style="display:flex;align-items:center;margin-bottom:15px;" v-if="item.styles.displayType !== 'inside'">
                        <label class="label" style="min-width:242px;margin-bottom:0;"
                        >List Height
                            <b>{{ item.styles.listing_img_height }}</b>
                            px</label
                        >
                        <div class="slider" style="width:100%">
                            <v-slider
                                    hide-details
                                    :min="90"
                                    :max="300"
                                    v-model="item.styles.listing_img_height"
                            ></v-slider>
                        </div>
                    </div>
                    <div style="display:flex;align-items:center;margin-bottom:15px;" v-if="item.styles.displayType === 'horizontal'">
                        <label class="label" style="min-width:242px;margin-bottom:0;"
                        >Width
                            <b>{{ item.styles.listing_img_width }}</b>
                            px</label
                        >
                        <div class="slider" style="width:100%">
                            <v-slider
                                    hide-details
                                    :min="50"
                                    :max="123"
                                    v-model="item.styles.listing_img_width"
                            ></v-slider>
                        </div>
                    </div>
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                        <label class="label" style="min-width:242px;margin-bottom:0;"
                        >Detail Height
                            <b>{{ item.styles.detail_listing_img_height }}</b>
                            px</label
                        >
                        <div class="slider" style="width:100%">
                            <v-slider
                                    hide-details
                                    :min="90"
                                    :max="300"
                                    v-model="item.styles.detail_listing_img_height"
                            ></v-slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div
                    style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 15px;
            "
            >
                <label class="label" style="float: left"
                >Show horizontal menu</label
                >
                <v-switch color="#6ab64a" v-model="item.horizontal"></v-switch>
            </div>
            <!--MOTIVATION --->
            <div
                    style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
            >
                <label class="label" style="float: left"
                >Show motivation text</label
                >
                <v-switch color="#6ab64a" v-model="item.motivation.show"
                ></v-switch>
            </div>
            <div
                    class="field"
                    v-if="item.motivation.show"
                    style="margin-bottom: 30px"
            >
                <label class="label">Change motivation text</label>
                <div class="control">
                    <Editor
                            v-model="item.motivation.content"
                            api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                            :init="options"
                    ></Editor>
                </div>
            </div>
            <!--MOTIVATION --->
            <div class="control" style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="display:block;margin-bottom:0;width:200px;">Link to tourist</label>
                <v-text-field
                        outlined
                        dense
                        hide-details
                        :placeholder="$t('constructor[\'Link\']')"
                        v-model="content.content"
                ></v-text-field>
            </div>
            <div class="control" style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom: 0;display: block">Show group</label>
                <v-switch color="#6ab64a" hide-details v-model="content.showGroup"></v-switch>
            </div>
            <div class="control" style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom: 0;display: block">Show location</label>
                <v-switch color="#6ab64a" hide-details v-model="content.styles.show_location"></v-switch>
            </div>
            <div class="control" style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom: 0;display: block">Show review score</label>
                <v-switch color="#6ab64a" hide-details v-model="content.styles.show_score_review"></v-switch>
            </div>
            <div class="control" style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom: 0;display: block">Show review count</label>
                <v-switch color="#6ab64a" hide-details v-model="content.styles.show_count_review"></v-switch>
            </div>
            <div class="control" style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom: 0;display: block">Show map</label>
                <v-switch color="#6ab64a" hide-details v-model="content.styles.show_map"></v-switch>
            </div>
            <div class="control" style="display:flex;align-items:center;margin-bottom:15px;" v-if="content.styles.show_map">
                <label class="label" style="width:200px;margin-bottom: 0;display: block">Zoom <b>{{content.styles.zoom}}</b></label>
                <div class="slider" style="width:100%;">
                    <v-slider
                            hide-details
                            :min="3"
                            :max="50"
                            v-model="content.styles.zoom"
                    ></v-slider>
                </div>
            </div>
            <div class="control" style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom: 0;display: block">Show direction button</label>
                <v-switch color="#6ab64a" hide-details v-model="content.styles.show_open_map_button"></v-switch>
            </div>
            <div class="control" style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom: 0;display: block">Show favorite button</label>
                <v-switch color="#6ab64a" hide-details v-model="content.styles.show_favorite"></v-switch>
            </div>
            <div class="control" style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom: 0;display: block">Show share button</label>
                <v-switch color="#6ab64a" hide-details v-model="content.styles.show_share"></v-switch>
            </div>
        </div>
    </div>
</template>

<script>
import $axios from "@/plugins/axios"
  export default {
    name: "TouristListing",
    props:['item','content'],
    components:{
      ListingBackground:() => import("./ToutistListingStyles/ListingBackground"),
      ListingLiningBackground:() => import("./ToutistListingStyles/ListingLiningBackground")
    },
    data(){
      return{
        fontStyleTypes: [
          { id: 1, value: "normal", label: "Normal" },
          { id: 0, value: "italic", label: "Italic" },
        ],
        show_title_color:false,
        positionTypes: [
          { id: 0, valueVertical: "flex-start", valueHorizontal: "flex-start" },
          { id: 1, valueVertical: "flex-start", valueHorizontal: "center" },
          { id: 2, valueVertical: "flex-start", valueHorizontal: "flex-end" },
          { id: 3, valueVertical: "center", valueHorizontal: "flex-start" },
          { id: 4, valueVertical: "center", valueHorizontal: "center" },
          { id: 5, valueVertical: "center", valueHorizontal: "flex-end" },
          { id: 6, valueVertical: "flex-end", valueHorizontal: "flex-start" },
          { id: 7, valueVertical: "flex-end", valueHorizontal: "center" },
          { id: 8, valueVertical: "flex-end", valueHorizontal: "flex-end" },
        ],
        fontFamilyTypes: [
          { id: 0, value: "Arial", label: "Arial" },
          { id: 1, value: "SF UI Text", label: "SF UI Text" },
          { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
          { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
          { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
          { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
          { id: 6, value: "Geometria", label: "Geometria" },
          { id: 7, value: "DIN Pro", label: "DIN Pro" },
          { id: 8, value: "Circe", label: "Circe" },
          { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
          { id: 10, value: "Acrom", label: "Acrom" },
          { id: 11, value: "Inter", label: "Inter" },
        ],
        show_listing_shadow_color:false,
        show_listing_border_color:false,
        show_lining_color:false,
        position:"center center",
      }
    },
    mounted() {
      if(this.item.styles.displayType === 'horizontal'){
        this.item.styles.displayType = 'default'
      }
    },
    methods:{
      changeSettings(item){
        item.styles.listingSettings = !item.styles.listingSettings;
      },
      setPosition() {
        this.content.styles.position = this.position;
      },
      changeListingStyle(item, style) {
        item.styles.displayType = style;
      }
    }
  }
</script>

<style scoped>

</style>
